<template>
    <div>
        <v-footer color="primary" dark id="about">
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <v-avatar tile size="40%">
                            <v-img src="../assets/images/logo-white.png" contain />
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h2>
                            حول المستشفى
                        </h2>
                        <div style="font-size: 14px">
                            السيدة خديجة الكبرى (عليها السلام) أول مستشفى تخصصي للمرأة في العراق ومنطقة الشرق الأوسط، افتتح بإشراف العتبة الحسينية المقدسة وبذلك انضم إلى مؤسساتها الصحية في هيئة الصحة والتعليم الطبي.

 في عام 2022 تمّ إنشاء مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة وتجهيزه بأحدث الأجهزة الطبية بسعة اجمالية قدرها 202 سريراً. وبذلك أصبحت مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة وجهة صحية للنساء من داخل وخارج العراق،  تمتلك أفضل الخدمات الطبية والكفاءات الطبية العالمية، كما تحرص على توفير خصوصية وخدمات لائقة للنساء، إضافة إلى اعتبار مستشفى السيدة خديجة الكبرى (عليها السلام) التخصصي للمرأة أول مستشفى جامعي تدريبي يوفر التعليم والتدريب في المجالات الصحية المختلفة لطلاب الكليات الطبية والتمريضية والصيدلانية.
                        </div>
                    </v-col>

                    <v-col cols="6">
                        <v-row class="mb-2 mr-1">
                            <a href="https://web.facebook.com/kwh.iq">
                                <v-avatar size="35" tile >
                                    <v-img src="../assets/images/facebook.png" contain />
                                </v-avatar>
                            </a>
                            <a href="https://www.instagram.com/kwh.iq/" class="mr-1">
                                <v-avatar size="35" tile>
                                    <v-img src="../assets/images/instagram.png" contain />
                                </v-avatar>
                            </a>
                            <a href="https://wa.me/+9647744700012" class="mr-1">
                                <v-avatar size="35" tile>
                                    <v-img src="../assets/images/whatsapp.png" contain />
                                </v-avatar>
                            </a>
                            <a href="https://wa.me/+9647744700012" class="mr-1">
                                <v-avatar size="35" tile>
                                    <v-img src="../assets/images/telegram.png" contain />
                                </v-avatar>
                            </a>
                        </v-row>
                        <h4>
                            تواصلي معنا
                        </h4>
                    </v-col>

                    <v-col cols="6" class="text-end ">
                        <h4>
                            <span @click="makePhoneCall()" class="cur-pointer" dir="ltr">5055</span>
                            
                            <v-icon class="mr-2">
                                fi fi-rr-phone-flip
                            </v-icon>
                        </h4>
                        <br />
                        <h4>
                            كربلاء المقدسة - حي الأسكان
                            <v-icon class="mr-2">
                                fi fi-rr-marker
                            </v-icon>
                        </h4>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
export default {
    methods: {
        makePhoneCall() {
            window.open("tel:5055");
        }
    }
}
</script>