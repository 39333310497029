<template>
  <v-app>
  
      <loading v-if="loading" />
      <div v-if="!loading" style="position: relative;">
          <v-row class="ma-4">
              <h1 class="secondary--text">
                {{ item.name }}
              </h1>
              <v-spacer />
              <v-btn outlined @click="$router.go(-1)" class="mr-12 btn">
                  <h4>رجوع</h4>
                  <v-icon>
                      fi fi-rr-arrow-small-left
                  </v-icon>
              </v-btn>
          </v-row>
      </div>
      <v-divider/>
  
      <v-row justify="center" align="start">
          <v-col cols="12" md="3">
              <v-container>
                  <v-card outlined>
                      <v-img :src="$url + item.imageUrl" lazy-src="@/assets/images/slider1.png" />
                  </v-card>
              </v-container>
          </v-col>
  
          <v-col cols="12" md="9">
              <v-container fluid>
                  <v-card outlined fluid style="background-color: #FAFAFA">
                      <v-container fluid>
                          <v-alert text color="primary">
                              <!-- <h3></h3> -->
                              {{ item.shortDescription }}
                          </v-alert>
                          <div v-html="item.content" class="mx-4" />
                      </v-container>
                  </v-card>
                  <br/>
                  <v-btn v-if="item.phoneNumber" depressed class="btn" @click="makePhoneCall(item.phoneNumber)" x-large  color="primary">
                    تواصل مع القسم
                    <v-icon class="mr-2">fi fi-rr-call-history</v-icon>
                  </v-btn>
              </v-container>
          </v-col>
      </v-row>
      <AFooter/>
  </v-app>
  </template>
  
  <style scoped>
  .head {
    width: 100%;
    height: 600px;
    display: block;
  }
  
  .phone-icon {
    position: absolute;
    top: 20px;
    left: 10px;
    color: white;
    cursor: pointer;
    z-index: 10;
  }
  </style>
  
  <script>
  import AFooter from '@/components/Footer.vue'
  
  export default {
      components: {
          AFooter
      },
      data() {
          return {
              loading: false,
              item: {}
          }
      },
  
      created() {
          this.getItem()
      },
  
      methods: {
          async getItem() {
              this.loading = true
              try {
                  let res = await this.$http.get(`Department/${this.$route.params.id}`)
                  this.item = res.data.result
              } catch (err) {
                  console.log(err)
              } finally {
                  this.loading = false
              }
          },
          makePhoneCall(input) {
            window.open(`tel:${input}`);
        }
      },
  }
  </script>
  